import * as React from 'react';

interface CaptchaProps {
	update: any;
	className?: string;
	withGuid?: boolean;
	iconClassName?: string;
	counter?: number;
}

interface CaptchaState {
	isLoading: boolean;
	src: string;
}

const generateGUID = function (): string {
	return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
		const r = Math.random() * 16 | 0, 
			v = c === 'x' ? r : (r & 0x3 | 0x8);
		
		return v.toString(16);
	});
};

export default class Captcha extends React.Component<CaptchaProps, CaptchaState> {
	path: string;
	code: string;

	public static defaultProps: Partial<CaptchaProps> = {
		className: '',
		withGuid: true,
		iconClassName: 'icon-refresh icons'
	};

	constructor(props: CaptchaProps) {
		super(props);

		this.path = 'captcha';

		this.code = generateGUID();

		this.state = {
			src: this.getSrc(),
			isLoading: false
		};
		
		this.reload = this.reload.bind(this);
	}

	componentWillMount() {
		this.props.update(this.code);
	}

	componentWillReceiveProps(nextProps: CaptchaProps) {
		if (nextProps.counter !== this.props.counter) {
			this.setState({ src: this.getSrc() });
		}
	}

	getSrc(): string {
		return `${this.path}?${this.props.withGuid ? `guid=${this.code}` : 'id='}&refresh=${new Date().getTime()}`;
	}

	reload(event: any) {
		event.preventDefault();

		this.setState({ src: this.getSrc() });
	}

	render(): JSX.Element {
		return (
			<div className="captcha-component">
				<img src={this.state.src} />
				<button className="captcha-component__reload" tabIndex={-1} type="button" onClick={this.reload}>
					<i className={`captcha-component__icon ${this.props.iconClassName}`}/>
				</button>
			</div>);
	}
}