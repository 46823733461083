import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import { Link } from 'react-router-dom';
import { request } from '@app/components/Api';

import Captcha from '@common/react/components/Forms/Captcha';

import {Fragment} from 'react';

type PageProps =
	& RouteComponentProps<{}>;

import * as Yup from 'yup';

import { Formik, FormikProps, Form, Field, FieldProps } from 'formik';
import Button from "@common/react/components/Forms/Button";

interface FormValues {
	email: string;
	captcha: string;
}

interface RecoverState {
	code: string,
	success: boolean,
	message: string,
	isLoading: boolean,
	counter: number
}

const validationSchema = Yup.object().shape({
	email: Yup.string().required(),
	captcha: Yup.string().required()
});


export default class Recover extends React.Component<PageProps, RecoverState> {
	constructor(props: PageProps) {
		super(props);

		this.state = {
			code: '',
			success: false,
			message: '',
			isLoading: false,
			counter: 0
		};

		this.handleSubmit = this.handleSubmit.bind(this);
		this.setCode = this.setCode.bind(this);
	}
	

	handleSubmit(values: FormValues, {resetForm}) {
		let cnt = this.state.counter + 1;
		this.setState({ isLoading: true });

		request('recover', {
			email: values.email,
			captcha: values.captcha,
			code: this.state.code
		}).then(() => {
			this.setState({
				code: '',
				success: true,
				message: '',
				isLoading: false,
				counter: cnt
			});
			
			resetForm();
			
		}).catch((e: string) => {
			this.setState({
				isLoading: false,
				message: e,
				counter: cnt
			});
		});
	}
	
	setCode(code: string) {
		this.setState({
			code: code
		});
	}

	private serverMessageRender() {
		if (this.state.message) {
			return (<div className="alert alert-danger">{this.state.message}</div>)
		}
	}

	private innerRender() {
		if (this.state.success) {
			return (
				<div className="text-center">
					<h3>We sent you an email with further instructions</h3>
					<br/>
					<Link to={'/login'} className="btn btn-primary">Login</Link>
				</div>
			);
		}

		return (
			<Fragment>
				<h3 className="enter-page__form-headline">Forget Password ?</h3>
				<p>Enter your e-mail address below to reset your password.</p>
				<Formik
					initialValues={{ email: '', captcha: '' }}
					onSubmit={this.handleSubmit}
					validationSchema={validationSchema}
					render={(formikBag: FormikProps<FormValues>) => (
						<Form>
							<Field name="email"
								render={({ field, form }: FieldProps<FormValues>) => (
									<div className={`form-group ${form.errors.email && form.touched.email ? 'has-error' : ''}`}>
										<div className="form-control-container_with-icon">
											<i className="fa fa-user form-control__icon"></i>
											<input className="form-control" type="text" id="email"  placeholder="Email" {...field}/>
										</div>	
										{form.touched.email && form.errors.email ? <div className="validation-message">{form.errors.email}</div> : ''}
									</div>
								)}
							/>
							<Field
								name="captcha"
								render={({ field, form }: FieldProps<FormValues>) => (
									<div className={`form-group ${form.touched.captcha && form.errors.captcha ? 'has-error' : ''}`}>
										<div className="is-relative">
											<Captcha update={this.setCode} counter={this.state.counter} />
											<input className="form-control" id="captcha" {...field} />
											{form.touched.captcha && form.errors.captcha ? <div className="validation-message">{form.errors.captcha}</div> : ''}
										</div>
									</div>
								)}
							/>
							<br/>
							<div className="clearfix">
								<div className="pull-left">
									<Link className="btn btn-default" to="/login">Back</Link>
								</div>
								<div className="pull-right">
									<Button className="btn btn-success" isLoading={this.state.isLoading}>Submit</Button>
								</div>
							</div>
						</Form>
					)}
				/>
				{this.serverMessageRender()}
			</Fragment>
			);
	}

	public render() {
		return (
			<div className="recover-page enter-page">
				<div className="enter-page__container container">
					<div className="enter-page__logo">
						<h1>VARZIS</h1>
						{/*<img src={require('@images/logo.jpg')} alt="Varner"/>*/}
					</div>
					<div className="enter-page__form">
						{this.innerRender()}
					</div>
				</div>
			</div>
		)
	}
}
